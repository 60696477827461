/**
 * @generated SignedSource<<20e71f3766ed211788513b7efc68ff0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectSelectorQuery$variables = {
  domain_name?: string | null | undefined;
  email?: string | null | undefined;
  type?: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type ProjectSelectorQuery$data = {
  readonly projectsBefore2403: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly is_active: boolean | null | undefined;
    readonly name: string | null | undefined;
    readonly resource_policy: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly projectsSince2403: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly is_active: boolean | null | undefined;
    readonly name: string | null | undefined;
    readonly resource_policy: string | null | undefined;
    readonly type: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly user: {
    readonly groups: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type ProjectSelectorQuery = {
  response: ProjectSelectorQuery$data;
  variables: ProjectSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain_name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = {
  "kind": "Variable",
  "name": "domain_name",
  "variableName": "domain_name"
},
v2 = {
  "kind": "Literal",
  "name": "is_active",
  "value": true
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_active",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resource_policy",
  "storageKey": null
},
v7 = {
  "alias": "projectsSince2403",
  "args": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "Variable",
      "name": "type",
      "variableName": "type"
    }
  ],
  "concreteType": "Group",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": "projectsBefore2403",
  "args": [
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "concreteType": "Group",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserGroup",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSelectorQuery",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectSelectorQuery",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46a724a36bc6cdc3ba9f724699fd9d4c",
    "id": null,
    "metadata": {},
    "name": "ProjectSelectorQuery",
    "operationKind": "query",
    "text": "query ProjectSelectorQuery(\n  $domain_name: String\n  $email: String\n  $type: [String]\n) {\n  projectsSince2403: groups(domain_name: $domain_name, is_active: true, type: $type) @since(version: \"24.03.0\") {\n    id\n    is_active\n    name\n    resource_policy\n    type\n  }\n  projectsBefore2403: groups(domain_name: $domain_name, is_active: true) @deprecatedSince(version: \"24.03.0\") {\n    id\n    is_active\n    name\n    resource_policy\n  }\n  user(email: $email) {\n    groups {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d01c92f7461bfc12c2f8e86d35c79f2b";

export default node;
